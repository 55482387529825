import nophoto from "../assets/images/nophoto.jpg";
import scramble from "../assets/images/IMG_1452.jpg";
import cherp from "../assets/images/IMG_1208.jpg";
import trub from "../assets/images/IMG_1218.jpg";
import potatoe from "../assets/images/IMG_4962.jpg";
import canele from "../assets/images/IMG_1114.jpg";
import danish_gol from "../assets/images/IMG_1066.jpg";
import bul_kor from "../assets/images/IMG_1042.jpg";
import ulit from "../assets/images/IMG_1029.jpg";
import cr_clas from "../assets/images//IMG_1086.jpg";
import cr_mind from "../assets/images/IMG_1098.jpg";
import cr_choc from "../assets/images/IMG_1101.jpg";
import tart_ya from "../assets/images/IMG_4360.jpg";
import tart_kar from "../assets/images/IMG_4954.jpg";
import tart_vish from "../assets/images/IMG_4418.jpg";
import chia_mang from "../assets/images/IMG_4872.jpg";
import tiramisu from "../assets/images/IMG_4886.jpg";
import sirnik from "../assets/images/IMG_4940.jpg";
import kashaparm from "../assets/images/IMG_4466.jpg";
import kashayabl from "../assets/images/IMG_4499.jpg";
import kashamango from "../assets/images/IMG_4511.jpg";
import bruskkrev from "../assets/images/IMG_5018.jpg";
import briofor from "../assets/images/IMG_5035.jpg";
import briokrev from "../assets/images/IMG_5048.jpg";
import saladkur from "../assets/images/IMG_4925.jpg";
import lapsha from "../assets/images/IMG_4682.jpg";
import supchech from "../assets/images/IMG_4626.jpg";
import pastamor from "../assets/images/IMG_4970.jpg";
import pastakarb from "../assets/images/IMG_4985.jpg";
import bachmal from "../assets/images/IMG_4380.jpg";
import tikjack from "../assets/images/IMG_4431.jpg";
import marsh from "../assets/images/IMG_4807.jpg";
import mnogomango from "../assets/images/IMG_4535.jpg";
import glintv from "../assets/images/IMG_4550.jpg";
import astrolatte from "../assets/images/IMG_4799.jpg";
import kurkuma from "../assets/images/IMG_4663.jpg";
import trufkap from "../assets/images/IMG_4848.jpg";

const breakfastData = [
  {
    title: "Каша из бурого риса с пюре из манго",
    description:
      "Сочная, фруктовая каша с ярким вкусом на основе бурого риса и кокосового молока",
    price: "450",
    photo: kashamango,
  },
  {
    title: "Каша овсяная с карамелизованным яблоком",
    description:
      "Сладкая каша на основе геркулеса с добавлением яблока и корицы станет отличным завтраком в осеннее утро",
    price: "450",
    photo: kashayabl,
  },
  {
    title: "Каша овсяная на курином бульоне с пармезаном и яйцом пашот",
    description:
      "Сытная каша на основе куриного бульона собственного приготовления с сыром пармезан и яйцом пашот - для любителей сытного завтрака",
    price: "550",
    photo: kashaparm,
  },
  {
    title: "Сырники",
    description:
      "Сырники из фермерского творога с муссом из свежей малины и кремом из сладкой, взбитой сметаны",
    price: "500",
    photo: sirnik,
  },
  {
    title: "Омлет / скрембл со свежей зеленью",
    description: "Классический завтрак с нежным омлетом или скрэмблом на выбор",
    price: "550",
    photo: scramble,
  },
];

const saladData = [
  {
    title: "Теплый салат с курицей и вешенками",
    description:
      "Теплый салат с жареными вешенками и нежной курочкой со свежими овощами и яйцом пашот",
    price: "720",
    photo: saladkur,
  },
  {
    title: "Бриошь с форелью",
    description:
      "Сладкая бриошь, подогретая на сливочном масле с соусом из яичных желтков, зеленью и форелью собственного посола",
    price: "900",
    photo: briofor,
  },
  {
    title: "Бриошь с креветками",
    description:
      "Бриошь с поджаренными креветками на подушке из свежих огурцов и зелени с яично-масляным соусом",
    price: "850",
    photo: briokrev,
  },
  {
    title: "Брускетта с креветками и апельсинами",
    description:
      "Брускетта из зернового хлеба с поджаренными креветками и свежим апельсином с соусом бальзамик",
    price: "770",
    photo: bruskkrev,
  },
];

const foodData = [
  /*{
    title: "Шакшука",
    description:
      "Сочная томатная шакшука на горячей сковородке с сыром брынза и крутонами из зернового хлеба",
    price: "650",
    photo: nophoto,
  },*/
  {
    title: "Суп тыквенно-чечевичный",
    description:
      "Крем суп из тыквы и чечевицы - сладковатый и пряный, станет отличным, сытным обедом в осенний день",
    price: "480",
    photo: supchech,
  },
  {
    title: "Суп куриный с лапшой",
    description:
      "Домашний куриный бульон с нежными фрикадельками из индейки со сливками и лапшой собственного приготовления",
    price: "450",
    photo: lapsha,
  },
  {
    title: "Паста с морепродуктами",
    description: "Спагетти с морепродуктами в сочном томатном соусе",
    price: "790",
    photo: pastamor,
  },
  {
    title: "Карбонара",
    description:
      "Классическая паста карбонара на основе куриного бульона с сыром пармезан",
    price: "700",
    photo: pastakarb,
  },
];

const dessertData = [
  {
    title: "Черничный пирог",
    description:
      "Нежный пирог с сочной черникой и хрустящей корочкой. Идеальный баланс сладости и свежести",
    price: "350",
    photo: cherp,
  },
  {
    title: "Канеле",
    description:
      "Французское лакомство с карамельной корочкой и нежной сердцевиной. Идеальное сочетание вкусов и текстур",
    price: "200",
    photo: canele,
  },
  {
    title: "Трубочка со сгущенкой",
    description:
      "Хрустящая трубочка, наполненная сладкой сгущенкой. Настоящее удовольствие в каждом укусе",
    price: "210",
    photo: trub,
  },
  {
    title: "Даниш с голубикой",
    description:
      "Воздушная слоенка с сочной голубикой и легким кремом. Идеально к утреннему кофе",
    price: "420",
    photo: danish_gol,
  },
  {
    title: "Булочка с корицей",
    description:
      "Мягкая булочка с ароматной корицей. Вкус тепла и уюта в каждом кусочке",
    price: "250",
    photo: bul_kor,
  },
  {
    title: "Улитка с изюмом",
    description:
      "Воздушная сдоба с изюмом и сладкой начинкой. Настоящее лакомство",
    price: "250",
    photo: ulit,
  },
  {
    title: "Круассан классика",
    description:
      "Хрустящий круассан с золотистой корочкой и нежной мякотью. Классика, которая всегда уместна",
    price: "230",
    photo: cr_clas,
  },
  {
    title: "Круассан миндаль",
    description:
      "Круассан с ароматом миндаля и сладкой начинкой. Французская роскошь на вашем столе",
    price: "300",
    photo: cr_mind,
  },
  {
    title: "Круассан шоколад",
    description:
      "Шоколадный круассан с богатой начинкой. Идеальный выбор для шоколадных гурманов",
    price: "300",
    photo: cr_choc,
  },
  {
    title: "Тарталетка ягодный микс",
    description:
      "Хрустящая песочная тарталетка с нежным заварным кремом и отборными свежими ягодами голубики и малины",
    price: "500",
    photo: tart_ya,
  },
  {
    title: "Тарталетка солёная карамель",
    description:
      "Хрустящая песочная тарталетка с арахисом, сырным кремом и тягучей соленой карамелью",
    price: "400",
    photo: tart_kar,
  },
  {
    title: "Тарталетка Вишня - Фисташка",
    description:
      "Тарталетка с фисташковым франжипаном, вишневым конфитюром и взбитым фисташковым ганашем",
    price: "460",
    photo: tart_vish,
  },
  {
    title: "Тирамису",
    description:
      "Классический итальянский десерт на основе нежного крема из сыра Маскарпоне и пропитанного в кофейном сиропе печенья Савоярди",
    price: "450",
    photo: tiramisu,
  },
  {
    title: "Чиа Пудинг",
    description:
      "Пудинг на основе семян чиа и кокосового молока, кусочки ананаса и пюре манго-маракуйя. Десерт подходит для вегетарианцев",
    price: "450",
    photo: chia_mang,
  },
  {
    title: "Картошка",
    description:
      "Классический десерт с богатым шоколадным вкусом и мягкой текстурой. Вкус детства",
    price: "220",
    photo: potatoe,
  },
];

const authorData = [
  {
    title: "Бах малиновый",
    description:
      "Нежный кофейный напиток с ярким вкусом сочной малины и легкой освежающей пряностью перечной мяты, созданный специально для ценителей сладких и необычных сочетаний",
    price: "450",
    photo: bachmal,
  },
  {
    title: "Тыквенный Джек",
    description:
      "Ароматный кофе с добавлением пряной тыквенной матчи, корицы и мёда, создающий атмосферу теплого осеннего вечера в каждой чашке",
    price: "450",
    photo: tikjack,
  },
  {
    title: "Раф у костра с маршмеллоу",
    description:
      "Кремовый раф с нотками ванильной груши, украшенный воздушными, слегка поджаренными маршмеллоу — словно уютный вечер у костра",
    price: "450",
    photo: marsh,
  },
  {
    title: "Много манго",
    description:
      "Экзотический напиток на основе эспрессо и сливок с пикантным вкусом пряного манго, украшенный слайсом сушеного манго для полного погружения в тропики",
    price: "450",
    photo: mnogomango,
  },
  {
    title: "Кофейный глинтвейн",
    description:
      "Согревающий кофейный напиток с ароматом специй и ярких вишневых нот, объединяющий энергию кофе и уют глинтвейна",
    price: "450",
    photo: glintv,
  },
  {
    title: "Трюфельный капучино",
    description:
      "Изысканный капучино с добавлением перемолотого трюфеля, придающего напитку глубокий и изящный вкус",
    price: "450",
    photo: trufkap,
  },
  {
    title: "Латте куркума-мед",
    description:
      "Полезный и ароматный латте с добавлением куркумы и натурального меда, объединяющий пряные и сладкие оттенки",
    price: "450",
    photo: kurkuma,
  },
  {
    title: "Астра-латте ореховый",
    description:
      "Сливочный латте с насыщенным ореховым вкусом и легкими нотками аниса, создающий неповторимую вкусовую композицию",
    price: "450",
    photo: astrolatte,
  },
  {
    title: "Каскара-апельсин",
    description:
      "Освежающий чай из кофейной ягоды каскары с добавлением сока свежего апельсина и меда, украшенный слайсами апельсина",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Пина колада",
    description:
      "Безалкогольный тропический напиток из кокосового молока, ананаса и бергамота, приправленный корицей и украшенный сушеным ананасом",
    price: "450",
    photo: nophoto,
  },
];

const classicData = [
  {
    title: "Эспрессо",
    description:
      "Классический эспрессо с насыщенным вкусом и богатым ароматом, заряжающий энергией",
    price: "250",
    photo: nophoto,
  },
  {
    title: "Капучино",
    description:
      "Сочетание эспрессо с нежной молочной пенкой, идеально сбалансированный вкус",
    price: "300-350",
    photo: nophoto,
  },
  {
    title: "Латте",
    description:
      "Мягкий кофейный напиток с большим количеством молока, для тех, кто любит нежные оттенки",
    price: "350",
    photo: nophoto,
  },
  {
    title: "Флэт уайт",
    description:
      "Крепкий двойной эспрессо с тонким слоем микропены, для истинных ценителей кофе",
    price: "320",
    photo: nophoto,
  },
  {
    title: "Раф",
    description:
      "Кремовый напиток на основе эспрессо и сливок с ванильными нотками, обволакивающий нежностью",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Какао",
    description:
      "Горячий шоколадный напиток с насыщенным вкусом какао, согревающий и поднимающий настроение",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Горячий шоколад",
    description:
      "Плотный и ароматный напиток из растопленного шоколада, настоящее удовольствие для сладкоежек",
    price: "450",
    photo: nophoto,
  },
  {
    title: "Бамбл из свежевыжатого апельсинового/грейпфрутового сока",
    description:
      "Освежающее сочетание эспрессо со свежевыжатым цитрусовым соком, бодрит и освежает одновременно",
    price: "480",
    photo: nophoto,
  },
  {
    title: "Фильтр",
    description:
      "Ароматный фильтр-кофе, раскрывающий богатство вкуса тщательно отобранных зерен",
    price: "250-300",
    photo: nophoto,
  },
];

const teaData = [
  {
    title: "Матча латте",
    description:
      "Традиционный японский зеленый чай матча с добавлением молока, насыщенный антиоксидантами",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Зеленый/черный/красный",
    description:
      "Классические сорта чая на ваш выбор: освежающий зеленый, бодрящий черный или ароматный красный",
    price: "200-350",
    photo: nophoto,
  },
  {
    title: "Гречишный/травяной",
    description:
      "Полезные и ароматные чаи из гречихи или травяных сборов для расслабления и гармонии",
    price: "200-350",
    photo: nophoto,
  },
  {
    title: "Облепиховый/Брусника-клюква",
    description:
      "Фруктовые чаи с богатым витамином C, обладающие ярким вкусом и ароматом",
    price: "300-450",
    photo: nophoto,
  },
];

const coldData = [
  {
    title: "Эспрессо тоник",
    description:
      "Освежающий микс эспрессо и тоника со льдом, идеальный выбор для жарких дней",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Айс латте",
    description:
      "Классический латте со льдом, сохраняющий нежность и мягкость вкуса",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Матча тоник",
    description:
      "Инновационное сочетание матча и тоника, придающее бодрость и свежесть",
    price: "430",
    photo: nophoto,
  },
  {
    title: "Айс матча",
    description:
      "Охлажденный напиток из матча со льдом, для ценителей зеленого чая в новом исполнении",
    price: "430",
    photo: nophoto,
  },
  {
    title: "Лимонад ягодный бах",
    description:
      "Домашний лимонад с добавлением свежих ягод, освежает и радует ярким вкусом",
    price: "400",
    photo: nophoto,
  },
  {
    title: "Фреш",
    description:
      "Свежевыжатый цитрусовый сок из лучших апельсинов и грейпфрутов, заряжающий энергией и витамином C",
    price: "450",
    photo: nophoto,
  },
];

export const menuData = [
  {
    group: "breakfast",
    name: "Завтраки",
    items: breakfastData,
  },
  {
    group: "salad",
    name: "Салаты",
    items: saladData,
  },
  {
    group: "food",
    name: "Основные блюда",
    items: foodData,
  },
  {
    group: "dessert",
    name: "Десерты",
    items: dessertData,
  },
  {
    group: "author",
    name: "Авторские напитки",
    items: authorData,
  },
  /*{
    group: "classic",
    name: "Горячие напитки",
    items: classicData,
  },
  {
    group: "tea",
    name: "Чай",
    items: teaData,
  },
  {
    group: "cold",
    name: "Холодные напитки",
    items: coldData,
  },*/
];
